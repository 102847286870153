<template>
  <div class="home">
    <vue-headful
      title="リモート技術者支援サービス"
      description="Description here"
      keywords="明るい未来のためのエンジニアリング"
    />
    <type-five
      :image-src="section_12[0].image"
      :title="section_12[0].title"
      :description="section_12[0].description"
    />
    <type-four>
      <v-row>
        <v-col v-for="(item, id) in section_14" :key="id" cols="12" sm="3">
          <card-type-one
            :image-src="item.image"
            :title="item.title"
            :description="item.description"
          />
        </v-col>
      </v-row>
    </type-four>
    <type-five
      :image-src="section_15[0].image"
      :title="section_15[0].title"
      :description="section_15[0].description"
    />
    <v-container>
      <type-six
        v-for="(item, id) in section_16"
        :key="id"
        :image-src="item.image"
        :title="item.title"
        :description="item.description"
        :style="[id % 2 === 1 ? { 'flex-direction': 'row-reverse' } : {}]"
      />
    </v-container>
  </div>
</template>

<script>
import TypeFour from "@/components/TypeFour";
import CardTypeOne from "@/components/CardTypeOne";
import TypeFive from "@/components/TypeFive";
import TypeSix from "@/components/TypeSix";

export default {
  components: {
    TypeSix,
    TypeFive,
    CardTypeOne,
    TypeFour
  },
  data() {
    return {
      section_12: [
        {
          image: "images/image-4.webp",
          title:
            "2020年秋サービス開始<br />リモート技術者支援<br />サービスのご紹介",
          description:
            "本サービスは、現場と技術者をリモートでつなぎ、現場に都度必要な技術のサポートができるサービスです" +
            "<br />" +
            "例えば、見積・CAD図作成・３D設計データ作成・現場での施工管理や安全書類作成業務といった技術が必要な業務を遠隔で技術者が時間単位でサポートしてくれるため、効率向上が期待されます" +
            "<br />" +
            "IT技術を駆使して、高齢技術者や健常ではない技術者の方々が活躍できる新たな働き方を創ります。"
        }
      ],
      section_13: [
        {
          bg_color: "#fff",
          text_color: "#000",
          image: "images/ハンギングビル建設.webp",
          title: "日本の建設技術を守る",
          subtitle: "私たちの使命",
          description:
            "リモート技術者支援サービスは、コロナウイルスへの感染を防止する新たな働き方の挑戦です。<br />" +
            "3密を防ぎながら、熟練技術者・女性技術者・高齢技術者・障害を抱えた技術者が活躍できる新しい働き方を確立し、これまでの完全現場主義から遠隔支援という働き方に向き合いながら、日本の建設技術がさらに向上していく一つの新しい選択肢になるように挑戦していきます<br />" +
            "日本の高度な技術を承継していくための、一つの可能性として皆様の協力でこの新しい働き方を育てていきましょう"
        }
      ],
      section_14: [
        {
          image: "images/client-1.webp",
          title: "登録",
          description: "登録フォームより記載"
        },
        {
          image: "images/client-2.webp",
          title: "面談　契約",
          description:
            "リモート技術者の一覧より選択し面談　気に入った熟練者とオンラインで契約"
        },
        {
          image: "images/client-3.webp",
          title: "リモート業務～評価",
          description:
            "熟練者と打ち合わせをしながら、業務を遂行していきます　最後にお互いの評価をして完了"
        },
        {
          image: "images/client-4.webp",
          title: "支払い",
          description: "​業務完了後、オンラインで決済で業務完了"
        }
      ],
      section_15: [
        {
          image: "images/image-2.webp",
          title: "リモート技術者支援サービス 導入のメリット",
          description: "建設現場の様々な要望にリモートで支援するサービスです"
        }
      ],
      section_16: [
        {
          image: "images/image-10.webp",
          title: "リモート３D設計業務",
          description:
            "<span class='font-18'>３D設計データを遠隔で作成します</span><br /><br />" +
            "ICT施工関する３D設計データを作成致します<br />" +
            "測量・施工・BIM/CIMデータ等も作成可能です"
        },
        {
          image: "images/image-11.webp",
          title: "リモート測量　ドローン測量のみ",
          description:
            "<span class='font-18'>現地での離陸着陸は現場職員様が行い、 リモートでドローン測量をサポートします</span><br /><br />" +
            "現場には必要なドローンや測量機器をこちらから郵送して、標定点位置決め、ドローンのフライトプラン、設定操作はリモートでこちらから指示、設定を行います。"
        },
        {
          image: "images/image-12.webp",
          title: "リモート見積",
          description:
            "<span class='font-18'>熟練者が見積業務を代行します</span><br /><br />" +
            "見積は受注に大きく影響します。<br />" +
            "根拠となる代価表や単価等は、各企業のその時の事情で 変動するため答えがありません<br />" +
            "お客様が納得する見積を作成することが重要であり不可欠です<br />" +
            "このリモート見積業務では、熟練者の技術力をお借りして 見積作成を実施します"
        },
        {
          image: "images/image-4.webp",
          title: "リモート施工管理",
          description:
            "<span class='font-18'>熟練者が現場施工管理をサポートします</span><br /><br />" +
            "現場に行けなくても必要な技術知識があれば、リモートで現場発生する問題や技術的な課題に取り組むことができます"
        }
      ]
    };
  }
};
</script>
